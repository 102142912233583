import { createTheme } from "@material-ui/core/styles"

const Theme = createTheme({
    palette: {
        primary: {
            main: "#00764b",
        },
        secondary: {
            main: "#2d363d"
        },
    },
    shape: {
        borderRadius: 2
    }
});

export default Theme;