import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch as RouteSwitch, Route, Redirect } from "react-router-dom";
import FullPageLoadingComponent from "./components/FullPageLoadingComponent";
import useAuthStore from "./context/AuthenticationContext";
import LoginPage from "./pages/login/LoginPage";
import AuthenticatedContainer from "./components/AuthenticatedContainer";
import useHolidayStore from "./context/HolidayStore";

// console.log = function () {};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
  },
}));

function App() {
  const isAuthenticated = useAuthStore((store) => store.isAuthenticated);
  const cookieLogin = useAuthStore((store) => store.cookieLogin);
  const [isCookieLoginLoading, setCookieLoginLoading] = useState(true);

  const refreshHolidays = useHolidayStore((state) => state.refresh);
  useEffect(() => {
    refreshHolidays();
  }, []);

  useEffect(() => {
    let isMounted = true;
    function callback(x) {
      // console.log(x);
      if (isMounted) setCookieLoginLoading(false);
    }

    cookieLogin(callback, callback);

    return () => (isMounted = false);
  }, [cookieLogin]);

  const classes = useStyles();
  if (isCookieLoginLoading) return <FullPageLoadingComponent />;
  else
    return (
      <div className={classes.root}>
        <RouteSwitch>
          <Route path="/login">
            {isAuthenticated && <Redirect to="/home" />}
            {!isAuthenticated && <LoginPage />}
          </Route>
          <Route path="/">
            {isAuthenticated && (
              <AuthenticatedContainer>
                <Route path="/home" exact>
                  Home
                </Route>
                <Route path="/about" exact>
                  About
                </Route>
                <Route path="/" exact>
                  /
                </Route>
              </AuthenticatedContainer>
            )}
            {!isAuthenticated && <Redirect to="/login" />}
          </Route>
        </RouteSwitch>
      </div>
    );
}

export default App;
