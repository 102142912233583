import moment from "moment";
import create from "zustand";
import { persist } from "zustand/middleware";
import http from "../http-common";

const defaultState = {
  nameSearch: "",
  chiefRef: null,
  startAt: { year: moment().year(), month: moment().month() + 1 },
  nextNItems: 3,
  data: {},
};

const useConstructionSiteMonthlyStore = create(
  persist(
    (set, get) => ({
      nameSearch: "",
      setNameSearch: (name) => set({ nameSearch: name }),
      chiefRef: null,
      setChiefRef: (id) => set({ chiefRef: id }),
      startAt: { year: moment().year(), month: moment().month() + 1 },
      setStartAt: (year, month) =>
        set({ startAt: { year: year, month: month } }),
      nextNItems: 3,
      setNextNItems: (count) => set({ nextNItems: count }),
      data: {},

      reset: () => {
        const cState = get();
        set({ ...cState, ...defaultState });
      },
      refresh: (year, month, onError, onSuccess) => {
        let url = `/constructions/year/${year}/month/${month}`;
        // TODO append possible filter
        let params = "";
        let state = get();
        if (state.nameSearch && state.nameSearch.length > 0)
          params = params + `name=${state.nameSearch}`;
        if (state.chiefRef) {
          if (params.length !== 0) params = params + "&";
          params = params + `chiefRef=${state.chiefRef}`;
        }

        if (params.length > 0) url = url + "?" + params;

        http
          .get(url)
          .catch((err) => {
            console.error(err);
            if (typeof onError === "function") onError(onError);
          })
          .then((res) => {
            if (res && res.status === 200) {
              const { data } = get();
              const yearObj = { ...data[year], [month]: res.data };
              const dataObj = { ...data, [year]: yearObj };
              set({ data: dataObj });
              if (typeof onSuccess === "function") onSuccess(res);
            }
          });
      },
      refreshAll: () => {
        const store = get();
        const years = Object.keys(store.data);
        if (Array.isArray(years)) {
          years.forEach((year) => {
            const months = Object.keys(store.data[year]);
            if (Array.isArray(months)) {
              months.forEach((month) => {
                store.refresh(year, month, null, null);
                /*
                        let subUrl = `/group-entries/year/${year}/month/${month}`;
                        const { groupIdFilter } = get();
                        if(groupIdFilter) subUrl = subUrl + `?groupId=${groupIdFilter}`;
                        http.get(subUrl)
                        .catch((err) => {
                            console.error(err);
                            // if(typeof(onError) === "function") onError(err);
                        })
                        .then((res) => {
                            if(res && res.status === 200) {
                                const { data } = get();
                                // console.log("cData:", data);
                                const yearObj = {...data[year], [month]: res.data };
                                // console.log("monthObj:", monthObj);
                                const dataObj = { ...data, [year]: yearObj };
                                // console.log("dataObj:", dataObj);
                                set({ data: dataObj });
                            }
                        })
                        */
              });
            }
          });
        }
      },
    }),
    { name: "ConstructionSiteMonthlyStore" }
  )
);

export default useConstructionSiteMonthlyStore;
