import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { BrowserRouter } from "react-router-dom";
import Theme from "./themes/Theme";

export default function ProviderContainer({ children }) {
    return (
        <MuiThemeProvider theme={Theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <BrowserRouter>
                    {children}
                </BrowserRouter>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    )
}