import create from "zustand";
import { persist } from "zustand/middleware";
import http from "../http-common";

const defaultState = {
  nameSearch: "",
  startDate: "",
  endDate: "",
  chiefRef: null,
  constructionSites: [],
  isLoading: false,
};

const useConstructionSiteStore = create(
  persist(
    (set, get) => ({
      nameSearch: "",
      setNameSearch: (name) => set({ nameSearch: name }),
      startDate: "",
      setStartDate: (startDate) => set({ startDate: startDate }),
      endDate: "",
      chiefRef: null,
      setChiefRef: (chiefRef) => set({ chiefRef: chiefRef }),
      setEndDate: (endDate) => set({ endDate: endDate }),
      applyFilter: (name, startDate, endDate, chiefRef) => {
        set({
          nameSearch: name,
          startDate: startDate,
          endDate: endDate,
          chiefRef: chiefRef,
        });
        get().refresh();
      },
      constructionSites: [],
      isLoading: false,

      reset: () => {
        const cState = get();
        set({ ...cState, ...defaultState });
      },
      refresh: (onError, onSuccess) => {
        set({ isLoading: true });
        let params = "";
        let state = get();
        if (state.nameSearch && state.nameSearch.length > 0)
          params = params + `name=${state.nameSearch}`;
        if (state.startDate && state.startDate.length > 0) {
          if (params.length !== 0) params = params + "&";
          params = params + `start-date=${state.startDate}`;
        }
        if (state.endDate && state.endDate.length > 0) {
          if (params.length !== 0) params = params + "&";
          params = params + `end-date=${state.endDate}`;
        }
        if (state.chiefRef) {
          if (params.length !== 0) params = params + "&";
          params = params + `chiefRef=${state.chiefRef}`;
        }
        let url = "/constructions";
        if (params.length > 0) url = url + "/filtered?" + params;
        // console.log(url);
        http
          .get(url)
          .catch((err) => {
            console.error(err);
            if (onError && typeof onError === "function") onError(err);
          })
          .then((res) => {
            if (res && res.status === 200) {
              set({ constructionSites: res.data });
              if (onSuccess && typeof onSuccess === "function") onSuccess(res);
            } else {
              console.error("Unknown response type: ", res);
            }
            set({ isLoading: false });
          });
      },
      addConstruction: (construction, onError, onSuccess) => {
        const refresh = get().refresh;
        http
          .post("/chief/constructions", construction)
          .catch((err) => {
            console.error(err);
            if (typeof onError === "function") onError(err);
          })
          .then((res) => {
            if (res && res.status === 200) {
              refresh();
              if (typeof onSuccess === "function") onSuccess(res);
            }
          });
      },
      updateConstruction: (construction, onError, onSuccess) => {
        const refresh = get().refresh;
        const id = construction.id;
        construction.id = undefined;
        http
          .patch("/chief/constructions/" + id, construction)
          .catch((err) => {
            console.error(err);
            if (typeof onError === "function") onError(err);
          })
          .then((res) => {
            if (res && res.status === 200) {
              refresh();
              if (typeof onSuccess === "function") onSuccess(res);
            }
          });
      },
      getConstruction: async (id) => {
        const response = await http.get(`/constructions/${id}`);
        return response;
      },
      removeConstruction: (id, onError, onSuccess) => {
        if (!id) {
          onError();
          return;
        }
        const refresh = get().refresh;
        http
          .delete(`/chief/constructions/${id}`)
          .catch((err) => {
            console.error(err);
            if (typeof onError === "function") onError(err);
          })
          .then((res) => {
            if (res && res.status === 200) {
              refresh();
              if (typeof onSuccess === "function") onSuccess(res);
            }
          });
      },
    }),
    { name: "ConstructionSiteOverviewStore" }
  )
);

export default useConstructionSiteStore;
