import create from "zustand";
import { persist } from "zustand/middleware";
import http from "../http-common";
import moment from "moment";

const cYear = moment().year();
const loadPrevYears = 5;
const loadNextYears = 5;


const useHolidayStore = create(persist((set, get) => ({
    loading: false,
    holidays: {}, // object that contains an array of holiday dates for each year (key)
    isHoliday: (year, month, day) => {
        const { holidays } = get();
        if(year === 2022 && month === 4 && day === 18) console.log("easter monday 2022:", )
        // console.log(year, month, day, "Holidays:", holidays);
        return holidays && holidays[year] && holidays[year][month] && holidays[year][month][day];
    },
    refresh: () => {
        for(let i = -loadPrevYears; i <= +loadNextYears; i++) {
            const year = cYear + i;
            http.get(`/holidays/year/${year}`)
            .catch((err) => {
                console.error(err);
            })
            .then((res) => {
                if(res && res.status === 200) {
                    const arr = res.data;
                    let newHolidays = {};
                    arr.forEach((dateArr) => {
                       const [year, month, day] = [...dateArr];
                       const oldYears = newHolidays[year] ? newHolidays[year] : [];
                       const oldMonths = newHolidays[year] && newHolidays[year][month] ? newHolidays[year][month] : [];
                       newHolidays = { ...newHolidays, [year]: { ...oldYears, [month]: { ...oldMonths, [day]: true }}}
                    });
                    const cHolidays = get(state => state.holidays);
                    // console.log("newHolidays:", newHolidays);
                    set({ holidays: { ...cHolidays.holidays, ...newHolidays }});
                }
            })
        }
    }
}), { name: "Holidays" }));

export default useHolidayStore;