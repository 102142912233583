import create from "zustand";
import { persist } from "zustand/middleware";
import http from "../http-common";

import moment from "moment";

const defaultState = {
  groupIdsFilter: [],
  startAt: {
    year: moment(new Date()).year(),
    month: moment(new Date()).month() + 1,
  },
  showItems: 3,
  data: {},
};

const useGroupEntryMonthlyStore = create(
  persist(
    (set, get) => ({
      groupIdsFilter: null,
      setGroupIdsFilter: (groupIds) => {
        set({ groupIdsFilter: groupIds });
      },
      startAt: {
        year: moment(new Date()).year(),
        month: moment(new Date()).month() + 1,
      },
      setStartAt: (year, month) => {
        set({ startAt: { year: year, month: month } });
      },
      showItems: 3,
      setShowItems: (count) => {
        set({ showItems: count });
      },
      data: {},

      reset: () => {
        const cState = get();
        set({ ...cState, ...defaultState });
      },
      refresh: (year, month, onError, onSuccess) => {
        let subUrl = `/group-entries/year/${year}/month/${month}`;
        const { groupIdsFilter } = get();
        if (Array.isArray(groupIdsFilter) && groupIdsFilter.length > 0) {
          let param = "?groupIds=";
          for (const groupId of groupIdsFilter) {
            param += groupId + ",";
          }
          subUrl = subUrl + param.slice(0, -1); // remove trailing comma
        }

        http
          .get(subUrl)
          .catch((err) => {
            console.error(err);
            if (typeof onError === "function") onError(err);
          })
          .then((res) => {
            if (res && res.status === 200) {
              const { data } = get();
              // console.log("cData:", data);
              const yearObj = { ...data[year], [month]: res.data };
              // console.log("monthObj:", monthObj);
              const dataObj = { ...data, [year]: yearObj };
              // console.log("dataObj:", dataObj);
              set({ data: dataObj });
              if (typeof onSuccess === "function") onSuccess(res);
            }
          });
      },
      refreshAll: () => {
        const store = get();
        const years = Object.keys(store.data);
        if (Array.isArray(years)) {
          years.forEach((year) => {
            const months = Object.keys(store.data[year]);
            if (Array.isArray(months)) {
              months.forEach((month) => {
                let subUrl = `/group-entries/year/${year}/month/${month}`;
                const { groupIdsFilter } = get();
                if (Array.isArray(groupIdsFilter) && groupIdsFilter.length > 0) {
                  let param = "?groupIds=";
                  for (const groupId of groupIdsFilter) {
                    param += groupId + ",";
                  }
                  subUrl = subUrl + param.slice(0, -1); // remove trailing comma
                }

                http
                  .get(subUrl)
                  .catch((err) => {
                    console.error(err);
                    // if(typeof(onError) === "function") onError(err);
                  })
                  .then((res) => {
                    if (res && res.status === 200) {
                      const { data } = get();
                      // console.log("cData:", data);
                      const yearObj = { ...data[year], [month]: res.data };
                      // console.log("monthObj:", monthObj);
                      const dataObj = { ...data, [year]: yearObj };
                      // console.log("dataObj:", dataObj);
                      set({ data: dataObj });
                    }
                  });
              });
            }
          });
        }
      },
    }),
    { name: "GroupEntryMonthlyStore" },
  ),
);

export default useGroupEntryMonthlyStore;
