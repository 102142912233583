import create from "zustand";
import http from "../http-common";

const default_state = {
  isAuthenticated: false,
  id: undefined,
  email: undefined,
  details: undefined,
  grantedAuthorities: undefined,
  error: false,
};

const useAuthStore = create((set) => ({
  ...default_state,
  cookieLogin: (errorCallback, responseCallback) => {
    http
      .post("/auth/cookie-login")
      .catch((err) => {
        set({ ...default_state });
        responseCallback(err);
      })
      .then((res) => {
        if (res && res.status === 200) {
          set({ isAuthenticated: true, ...res.data });
        } else {
          set({ ...default_state });
        }
        responseCallback(res);
      });
  },
  emailPasswordLogin: (email, password, errorCallback, responseCallback) => {
    http
      .post("/auth/login", { email, password })
      .catch((err) => {
        set({ ...default_state });
        errorCallback(err);
      })
      .then((res) => {
        if (res && res.status === 200) {
          set({ isAuthenticated: true, ...res.data });
        } else {
          set({ ...default_state });
        }
        responseCallback(res);
      });
  },
  logout: () => {
    http
      .post("/auth/logout")
      .catch((err) => {
        set({ ...default_state });
      })
      .then((res) => {
        set({ ...default_state });
      });
  },
}));

export default useAuthStore;
