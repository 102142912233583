import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.error.dark,
        }
    },

}));

export default function ErrorButton({className, ...props}) {
    const classes = useStyles();
    return <Button className={`${classes.root} ${className}`} {...props} />
}