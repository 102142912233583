import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";



export default class IndexErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        console.error(error);
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // put in log
    }

    render() {
        if(this.state.hasError) {
            return <ErrorBoundaryFallback />
        } else {
            return this.props.children;
        }
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    paper: {
        padding: theme.spacing(3),
        backgroundColor: "rgb(255, 28, 28)",
        textAlign: "center",
        color: "white",
    }
}))

function ErrorBoundaryFallback(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h5">Das hätte nicht passieren sollen :-(<br />Bitte lade die Seite erneut.</Typography>
            </Paper>
        </div>
    )
}
