import React, { lazy, useState, Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Hidden,
  Typography,
  Popover,
  Divider,
  Drawer,
  Button,
  List,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { PersonOutlineOutlined, MenuOutlined } from "@material-ui/icons";
import useAuthStore from "../context/AuthenticationContext";
import ErrorButton from "./ErrorButton";
import { Route, Redirect } from "react-router-dom";
import useConstructionSiteMonthlyStore from "../context/ConstructionSiteMonthlyStore";
import useGroupEntryMonthlyStore from "../context/GroupEntryMonthlyStore";
import useConstructionSiteStore from "../context/ConstructionSiteStore";
const AdminPage = lazy(() => import("../pages/admin/AdminPage"));
const GroupsPage = lazy(() => import("../pages/groups/GroupsPage"));
const GroupsWeeklyViewPage = lazy(() =>
  import("../pages/groups/calendar/weeklyView/GroupsWeeklyViewPage"),
);
const GroupsMonthlyViewPage = lazy(() =>
  import("../pages/groups/calendar/monthlyView/GroupsMonthlyViewPage"),
);
const GroupsOverviewPage = lazy(() =>
  import("../pages/groups/calendar/overview/GroupsOverviewPage"),
);
const ConstructionsOverviewPage = lazy(() =>
  import("../pages/constructions/overview/ConstructionsOverviewPage"),
);
const ConstructionsMonthlyViewPage = lazy(() =>
  import("../pages/constructions/monthlyView/ConstructionsMonthlyViewPage"),
);
const ConstructionsWeeklyViewPage = lazy(() =>
  import("../pages/constructions/weeklyView/ConstructionsWeeklyViewPage"),
);
const DrawerContent = lazy(() => import("./DrawerContent"));

const app_bar_height = 70;
const drawer_width = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
  },
  appBar: {
    width: "100vw",
    height: app_bar_height,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.spacing(2),
    },
  },
  beta: {
    fontSize: theme.typography.caption.fontSize,
  },
  appBarSubItem: {
    color: "white",
  },
  userMenu: {
    paddingTop: theme.spacing(1),
    minWidth: 200,
    maxWidth: 300,
    display: "inherit",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  clearDataLabel: {
    margin: 0,
    marginTop: theme.spacing(1),
  },
  logoutButton: {
    width: "100%",
  },
  drawerContent: {
    paddingTop: 0,
    width: drawer_width,
    height: "100vh",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    [theme.breakpoints.up("lg")]: {
      paddingTop: app_bar_height,
      marginTop: -1,
    },
  },
  mobileDrawerCloseButton: {
    height: app_bar_height,
    width: drawer_width,
    padding: 0,
    color: theme.palette.secondary.contrastText,
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid",
    borderRadius: 0,
    borderColor: theme.palette.secondary.contrastText,
    margin: 0,
    marginBottom: -1, // match with borderBottom px
  },
  content: {
    position: "absolute",
    top: app_bar_height,
    left: 0,
    bottom: 0,
    right: 0,
    [theme.breakpoints.up("lg")]: {
      left: drawer_width,
    },
    overflow: "scroll",
  },
}));

export default function AuthenticatedContainer(props) {
  const email = useAuthStore((store) => store.email);
  const details = useAuthStore((store) => store.details);
  const grantedAuthorities = useAuthStore((store) => store.grantedAuthorities);
  const logout = useAuthStore((store) => store.logout);

  const [clearDataOnLogout, setClearDataOnLogout] = useState(false);
  function onClearDataOnLogoutChange(e) {
    setClearDataOnLogout(e.target.checked);
  }

  const constructionSiteMonthlyStore = useConstructionSiteMonthlyStore();
  const constructionSiteOverviewStore = useConstructionSiteStore();
  const groupEntryMonthlyStore = useGroupEntryMonthlyStore();
  function internalLogout() {
    constructionSiteMonthlyStore.reset();
    groupEntryMonthlyStore.reset();
    constructionSiteOverviewStore.reset();

    logout();
    if (clearDataOnLogout) localStorage.clear();
  }

  const [isMobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [userMenuAnchor, setUserMenuAnchor] = useState();

  function showMobileDrawer() {
    setMobileDrawerOpen(true);
  }
  function closeMobileDrawer() {
    setMobileDrawerOpen(false);
  }

  function showUserMenu(e) {
    setUserMenuAnchor(e.currentTarget);
  }
  function closeUserMenu(e) {
    setUserMenuAnchor(null);
  }

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.appBar}>
        <Hidden lgUp>
          <IconButton className={classes.appBarSubItem} onClick={showMobileDrawer}>
            <MenuOutlined />
          </IconButton>
        </Hidden>
        <Hidden xsDown>
          <Typography className={classes.title} variant="h4">
            Reissmüller Zeitplaner<span className={classes.beta}> .v1.0</span>
          </Typography>
        </Hidden>
        <Hidden smUp>
          <Typography className={classes.title} variant="h5">
            Reissmüller Zeitplaner<span className={classes.beta}> .v1.0</span>
          </Typography>
        </Hidden>
        <IconButton className={classes.appBarSubItem} onClick={showUserMenu}>
          <PersonOutlineOutlined />
        </IconButton>
        <Popover
          anchorEl={userMenuAnchor}
          onClose={closeUserMenu}
          open={Boolean(userMenuAnchor)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <span className={classes.userMenu}>
            <Typography variant="body2">Email:</Typography>
            <Typography variant="body1" className={classes.email}>
              {email}
            </Typography>
            <Divider />
            <Typography variant="body2">Details:</Typography>
            <Typography variant="body1">{details}</Typography>
            {Array.isArray(grantedAuthorities) && (
              <>
                <Divider />
                <Typography variant="body2">Autoritäten:</Typography>
                {grantedAuthorities.map((string) => (
                  <Typography variant="caption">{string}</Typography>
                ))}
                <Divider />
              </>
            )}
          </span>
          <FormControlLabel
            label="Bei Logout lokale Daten löschen"
            className={classes.clearDataLabel}
            control={
              <Checkbox
                checked={clearDataOnLogout}
                onChange={onClearDataOnLogoutChange}
                style={{ margin: 0, padding: 0 }}
              />
            }
          />

          <ErrorButton className={classes.logoutButton} onClick={internalLogout}>
            Logout
          </ErrorButton>
        </Popover>
      </div>
      <nav className={classes.drawer}>
        <Hidden lgUp>
          <Drawer
            variant="temporary"
            open={isMobileDrawerOpen}
            onClose={closeMobileDrawer}
            keepMounted
          >
            <List className={classes.drawerContent}>
              <Button
                className={classes.mobileDrawerCloseButton}
                variant="outlined"
                onClick={closeMobileDrawer}
              >
                Schließen
              </Button>
              <Suspense fallback={<div>Bitt warten..</div>}>
                <DrawerContent onClick={closeMobileDrawer} />
              </Suspense>
            </List>
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer variant="permanent" open>
            <List className={classes.drawerContent}>
              <Suspense fallback={<div>Bitte warten..</div>}>
                <DrawerContent onClick={closeMobileDrawer} />
              </Suspense>
            </List>
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.content}>
        <Suspense fallback={<div>Loading...</div>}>
          <Route path="/admin" exact component={AdminPage} />
          <Route path="/groups" exact component={GroupsPage} />
          <Route path="/constructions/overview" exact component={ConstructionsOverviewPage} />
          <Route path="/constructions/monthly" exact component={ConstructionsMonthlyViewPage} />
          {/*
          <Route
            path="/constructions/weekly"
            exact
            component={ConstructionsWeeklyViewPage}
          />
          */}
          <Route path="/groups/monthly" exact component={GroupsMonthlyViewPage} />
          {/*<Route path="/groups/weekly" exact component={GroupsWeeklyViewPage} />*/}
          <Route path="/groups/overview" exact component={GroupsOverviewPage} />
          <Route path="/">
            <Redirect to="/constructions/overview" />
          </Route>
        </Suspense>
      </div>
    </div>
  );
}
