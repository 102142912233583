import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, TextField, Button, Typography, Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import useAuthStore from "../../context/AuthenticationContext";
import http from "../../http-common";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    maxWidth: 350,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    "& > *": {
      width: "100%",
      marginBottom: theme.spacing(1),
    },
  },
  header: {
    color: (props) => (props.disabled ? theme.palette.action.disabled : theme.palette.primary.main),
  },
  alertSubtitle: {
    lineHeight: "130%",
  },
  alertDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  version: {
    position: "absolute",
    left: theme.spacing(1),
    bottom: theme.spacing(1),
    color: theme.palette.secondary.contrastText,
  },
}));

export default function LoginPage(props) {
  const [isServerReachable, setServerReachable] = useState(false);
  const [showServerNotReachableAlert, setShowServerNotReachableAlert] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const emailPasswordLogin = useAuthStore((store) => store.emailPasswordLogin);

  const history = useHistory();

  function onEmailChange(e) {
    setError(false);
    setEmail(e.target.value);
  }

  function onPasswordChange(e) {
    setError(false);
    setPassword(e.target.value);
  }

  function onPasswordKeyDown(e) {
    if (
      email &&
      email !== "" &&
      password &&
      password !== "" &&
      (e.which === 13 || e.target.keyCode === 13 || e.target.key === "Enter")
    )
      onLoginClick();
  }

  function onLoginClick(e) {
    setLoading(true);
    function errorCallback(err) {
      setError(true);
      setLoading(false);
    }
    function responseCallback(res) {
      if (res && res.status) history.push("/constructions/overview");
      else setError(true);
      setLoading(false);
    }

    emailPasswordLogin(email, password, errorCallback, responseCallback);
  }

  function closeServerUnreachableAlert() {
    setShowServerNotReachableAlert(false);
  }

  useEffect(() => {
    http
      .get("/test/ping")
      .catch((err) => {
        setServerReachable(false);
        setShowServerNotReachableAlert(true);
      })
      .then((res) => {
        if (res) setServerReachable(true);
        else {
          setServerReachable(false);
          setShowServerNotReachableAlert(true);
        }
      });
  }, []);

  const classes = useStyles({ disabled: !isServerReachable });
  return (
    <div className={classes.root}>
      <Paper component="form" className={classes.paper} disabled>
        <Typography variant="h4" className={classes.header}>
          Login
        </Typography>
        <TextField
          variant="outlined"
          color="primary"
          type="email"
          value={email}
          onChange={onEmailChange}
          label="Email"
          error={isError}
          disabled={!isServerReachable}
        />
        <TextField
          variant="outlined"
          color="primary"
          type="password"
          value={password}
          onChange={onPasswordChange}
          onKeyDown={onPasswordKeyDown}
          label="Password"
          error={isError}
          disabled={!isServerReachable}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onLoginClick}
          disabled={isError || isLoading || password === "" || email === "" || !isServerReachable}
        >
          {isLoading ? "Bitte warten" : "Login"}
        </Button>
      </Paper>
      <Snackbar
        open={showServerNotReachableAlert}
        autoHideDuration={60_000}
        onClose={closeServerUnreachableAlert}
      >
        <Alert onClose={closeServerUnreachableAlert} severity="error">
          <Typography variant="subtitle1" className={classes.alertSubtitle}>
            Der Server ist derzeit leider nicht erreichbar, bitte versuche es später erneut.
          </Typography>
          <Divider className={classes.alertDivider} />
          Sollte dieses Problem häufiger auftreten, wende dich bitte an deinen System-Administrator!
        </Alert>
      </Snackbar>
      <Typography variant="body1" className={classes.version}>
        Version 1.1
      </Typography>
    </div>
  );
}
